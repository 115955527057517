<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reporte - Pagos</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate
            ">
              <b-row class="justify-content-center">
                <b-col md="2">
                  <b-form-group label="T. Habitación :">
                    <b-form-select @change="ListRoom"  v-model="report.id_type_room" :options="type_room"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Habitación :">
                    <b-form-select  v-model="report.id_room" :options="rooms"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="2">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: </label>
                    <b-form-select v-model="report.payment_type" :options="payment_type"></b-form-select>
                  </b-form-group>
                </b-col>

                

                <b-col md="2">
                  <b-form-group label="Desde:">
                    <b-form-input type="date" class="text-center" v-model="report.from"></b-form-input>
                    <small v-if="errors.code" class="form-text text-danger">Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Desde:">
                    <b-form-input type="date" class="text-center" v-model="report.to"></b-form-input>
                    <small v-if="errors.code" class="form-text text-danger">Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Usuario :">
                    <b-form-select  v-model="report.id_user" :options="users"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="1">
                  <b-form-group label=".">
                    <b-button type="submit" class="form-control btn btn-primary" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col md="1">
                  <b-form-group label=".">
                    <b-button type="button" @click="ReportExcel" class="form-control btn btn-success" variant="success"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>
      
              </b-row>
            </b-form>


            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Fecha</th>
                    <th width="8%" class="text-center">Comprobante</th>
                    <th width="25%" class="text-center">Cliente</th>
                    <th width="10%" class="text-center">Hab.</th>
                    <th width="5%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">M. Pago</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="12%" class="text-center">Concepto</th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, it) in reports" :key="it">
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date + " " + item.broadcast_time}}</td>
                    <td class="text-center"> {{ item.voucher_type+" "+item.serie+"-"+item.number }}</td>
                    <td class="text-left"> {{ item.client_name +' - '+item.client_document_number }}</td>
                    <td class="text-left"> {{ item.room_type_name+" "+item.room_number }}</td>
                    <td class="text-center"> {{ item.coin }}</td>
                    <td class="text-left"> {{ NamePaymentType(item.payment_type) }}</td>
                    <td class="text-right"> {{ item.total }}</td>
                    <td class="text-left"> {{ item.email }}</td>
                    <td class="text-left"> {{ item.observation }}</td>

            
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"> </td>
                    <td class="text-left"> </td>
                    <td class="text-left"></td>
                    <td class="text-center"> </td>
                    <td class="text-left"></td>
                    <td class="text-right"> {{ total }}</td>
                    <td class="text-left"> </td>
                    <td class="text-left"> </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="Validate" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row> -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style>
.table-responsive{
  min-height: 15px !important;
}
</style>
<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");

export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
  },
  data() {
    return {
      module: "ReportGuest",
      role: 1,
      type_room:[{value:0,text: ' -- Todos -- '}],
      rooms:[{value:0,text: ' -- Todos -- '}],
      users:[{value:0,text: ' -- Todos -- '}],
      states:[
        {value:0,text: ' -- Todos -- '},
        {value:1,text: 'Ocupado'},
        {value:2,text: 'Libre para Mantenimiento'},
        {value:3,text: 'En Mantenimiento'},
        {value:4,text: 'Finalizado'},
      ],
      clients:[],
      client:null,
      total : 0,
      report: {
        id_type_room: 0,
        id_room: 0,
        from: moment(new Date()).local().format("YYYY-MM-DD"),
        to: moment(new Date()).local().format("YYYY-MM-DD"),
        id_user: 0,
        state: 0,
        id_client: "",
        payment_type:'all',
      },

      perPage: 40,
      currentPage: 1,
      rows: 0,
      reports: [],

      payment_type:[
            {value:'all',text:'Todos'},
            {value:'01-001',text:'Contado - Efectivo'},
            {value:'01-002',text:'Contado - Visa'},
            {value:'01-003',text:'Contado - Mastercard'},
            {value:'01-004',text:'Contado - American Express'},
            {value:'01-005',text:'Contado - Transferencia de Fondos'},
            {value:'03-000',text:'Crédito'},
        ],

      //errors
      errors: {
        from: false,
        to: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListTypeRoom();
    this.ListUser();

  },
  methods: {
    SearchClients(search, loading) {
      let me = this;
      let url = this.url_base + "search-clients/" + search;
      if (search !== "") {
        loading(true);
        axios({
          method: "GET",
          url: url,
        }).then(function (response) {
              me.clients = response.data;
              loading(false);
        })
      }
    },
    ListTypeRoom,
    ListRoom,
    ListUser,
    Report,
    Validate,
    ReportExcel,
    NamePaymentType,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function NamePaymentType(code) {
  let name = '';
  switch (code) {
    case '01-001': name = 'Efectivo'; break;
    case '01-002': name = 'Visa'; break;
    case '01-003': name = 'Mastercard'; break;
    case '01-004': name = 'American Express'; break;
    case '01-005': name = 'Transferencia de Fondos'; break;
    case '03-000': name = 'Crédito'; break;
  }
  return name;
}

//listar usuario
function ListTypeRoom() {
  let me = this;
  let url = this.url_base + "active-type-room";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      me.type_room = [];
      me.type_room.push({value:0,text: ' -- Todos -- '})
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.type_room.push({value:element.id_type_room,text: element.name})
        }
     
      }
    })
}

function ListRoom() {
  let me = this;
  let url = this.url_base + "active-room-by-type-room/" + this.report.id_type_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      me.rooms = [];
      me.rooms.push({value:0,text: ' -- Todos -- '})
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.rooms.push({value:element.id_room,text: element.room_number})
        }
     
      }
    })
}

function ListUser() {
  let me = this;
  let url = this.url_base + "active-users";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      me.users = [];
      me.users.push({value:0,text: ' -- Todos -- '})
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.users.push({value:element.id_user,text: element.email})
        }
     
      }
    })
}

function Report(_this) {
  

  let me = _this;
  let url = me.url_base + "report/payment";
  me.report.id_client = me.client == null ? 0:me.client.id;
  let data = me.report;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.reports = response.data.result;

        for (let index = 0; index < me.reports.length; index++) {
          const element = me.reports[index];
          me.total += parseFloat(element.total);
        }

        me.total = parseFloat(me.total).toFixed(2);
      }
    })
  
}

function Validate() {
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;
 
  if (this.errors.from) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.to) { this.validate = true; return false;}else{ this.validate = false; }

  Report(this);

}

function ReportExcel() {
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;
  if (this.errors.from) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.to) { this.validate = true; return false;}else{ this.validate = false; }
  let me = this;
  let url = this.url_base + "report-payment-excel/"+me.report.id_type_room+"/"+me.report.id_room+"/"+me.report.from+"/"+me.report.to+"/"+me.report.id_user+"/"+me.report.payment_type;
  window.open(url,'_blank');
}

</script>
